const Audio = ({ demo }) => {
    return (
        <div>
            { demo.name }
            <br />
            <audio controls>
                <source src={ demo.url } type="audio/mpeg"/>
            </audio>
            <br />
            <br />
        </div>
    )
}

export default function Demos() {
    const demos = [
        { name: 'first blooms',       url: '/ephemeral/first-blooms.mp3' },
        { name: 'ever changing',      url: '/ephemeral/ever-changing.mp3' },
        { name: 'under water',        url: '/ephemeral/under-water.mp3' },
        { name: 'jcmf',               url: '/ephemeral/jcmf.mp3' },
        { name: 'drift / never over', url: '/ephemeral/drift-never-over.mp3' },
    ]

    return (
        <div>
            { demos.map((demo) => <Audio demo={demo}/> ) }
        </div>
    )
}