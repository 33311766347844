export default function Bio() {
    const DOWNLOADS = [
        { title: "Ephemeral tracks, vol. 2",        link: "./downloads/ephemeral-2.zip" },
        { title: "beyond the tall trees somewhere", link: "./downloads/beyond-the-tall-trees.zip" },
        { title: "Ephemeral tracks, vol. 1",        link: "./downloads/ephemeral-1.zip" },
        { title: "self-titled",                     link: "./downloads/xor.zip" }
    ]
    return (
        <div className="container">
            <div className="row mb-30">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-30">
                    <a href="./img/1.jpeg" target="_blank">
                        <img src="./img/1.jpeg" className="photo"/>
                    </a>
                </div>
                {/* <div className="col-lg-4 col-md-6 col-sm-12">
                    <img src="./img/2.jpeg" className="photo"/>
                </div> */}
                <div className="col-lg-4 col-md-6 col-sm-12 mb-30">
                    <a href="./img/3.jpeg" target="_blank">
                        <img src="./img/3.jpeg" className="photo"/>
                    </a>
                </div>
            </div>

            <div className="row">
                <div class="col-12 mb-30">
                    <h4>Downloads</h4>
                    {
                        DOWNLOADS.map(d => {
                            return (
                                <div><a href={ d.link } class="link">{ d.title }</a></div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-30">
                <h4>Bio</h4>
                <p>
                With haunting melodies, pulsating rhythms, and seductive hooks, XOR propels electronic music 
                into a realm of sonic uncertainty. At times ambient, at times darkwave or synthpop, the 
                tracks draw listeners into a labyrinthine soundscape where the familiar dissolves into the enigmatic. 
                </p>
                <p>
                Matthew had been recording under various monikers over the past decade before XOR. Somewhat 
                arbitrarily, he named the project XOR after the logic gate inspired by his burgeoning interest 
                in software development. His debut full-length was an electropop album, written and recorded 
                amidst the pandemic's disquiet.
                </p>
                <p>
                In recent years, Matthew began exploring the world of modular synthesizers, reshaping mundane days 
                of remote work into impromptu compositions he would later live-stream against sunsets or amidst the 
                flowers in his garden. "Ephemeral Tracks, vol. 1" emerged in 2022 from these sonic experiments, 
                followed by the 2023 release of “vol. 2.” The name draws inspiration from the ephemeral woodland 
                wildflowers that briefly bloom in Appalachia's early spring, mirroring the transient nature of his 
                modular-first compositions.
                </p>
                <p>
                In 2023, XOR also released the EP "beyond the tall trees somewhere.” Branching out from his previously 
                more straightforward darkwave, the five tracks explore the complexities of human existence, the nebulous 
                boundaries between natural and artificial, and the discomfort of existing in a dying world.
                </p>
                <p>
                XOR has remixed tracks for many artists, including Secret Shame, Cold Choir, and Shadow Age, adding 
                his unique sonic signature to each collaboration.
                </p>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-30">
                    <h4>Press</h4>
                    <p className="mb-20">
                    <em className="italic">"...a haunting punktronic dark wave sound that could exist in a Blade Runner movie."</em>
                    <br/>&nbsp;&nbsp;&nbsp;- American Pancake
                    </p>
                    <p className="mb-20">
                    <em className="italic">"...sonically reminiscent of minimal synth variation on 'Violator' and 'Playing the Angel' era Depeche Mode, tinged with melancholia specifically tied to isolation."</em>
                    <br/>&nbsp;&nbsp;&nbsp;- Post-punk.com
                    </p>
                    <p className="mb-20">
                    <em className="italic">"...there’s something truly special about what XOR is doing in the realms of electronic pop."</em>
                    <br/>&nbsp;&nbsp;&nbsp;- Austin Town Hall
                    </p>

                    <p>
                    <a href="https://post-punk.com/xor-covers-afis-god-called-in-sick-today/" target="_blank" className="td-underline orange">Post-punk.com</a> God Called in Sick Today single coverage 
                    </p>
                    <p>
                    <a href="http://www.destroyexist.com/2021/10/cold-choir-paper-flowers-remix-by-xor.html" target="_blank" className="td-underline orange">Destroy // Exist</a> Paper Flowers (Cold Choir remix)
                    </p>
                    <p>
                    <a href="https://summertriiangle.blogspot.com/2021/06/xor-saturn-returns-official-video.html" target="_blank" className="td-underline orange">Summer Triangle</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="https://www.tonspion.de/news/die-besten-indie-pop-neuheiten" target="_blank" className="td-underline orange">Tonspion</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="https://cvltnation.com/rest-in-the-electro-presence-of-xor-saturn-returns/" target="_blank" className="td-underline orange">CVLT Nation</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="http://lastdaydeaf.com/dope-109/" target="_blank" className="td-underline orange">Last Day Deaf</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="https://www.darkeninheart.com/2021/06/saturn-returns-by-xor.html" target="_blank" className="td-underline orange">Darken in Heart</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="https://post-punk.com/xor-orbits-the-solar-system-with-the-dark-synth-pop-melodies-of-saturn-returns/" target="_blank" className="td-underline orange">Post-punk.com</a> Saturn Returns premiere
                    </p>
                    <p>
                    <a href="https://www.destroyexist.com/2021/06/xor-saturn-returns.html" target="_blank" className="td-underline orange">Destroy // Exist</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="https://austintownhall.com/2021/06/21/subtle-electro-pop-from-xor/" target="_blank" className="td-underline orange">Austin Town Hall</a> Saturn Returns single coverage
                    </p>
                    <p>
                    <a href="https://diamonddeposits.tumblr.com/post/652392139510185984/xor-path-black-light-lit-pop-with-razor-sharp" target="_blank" className="td-underline orange">Diamond Deposits</a> Path single coverage
                    </p>
                    <p>
                    <a href="https://electrozombies.com/magazine/article/synth-pop-newcomer-2021/" target="_blank" className="td-underline orange">Electro Zombies</a> Path single coverage
                    </p>
                    <p>
                    <a href="https://www.darkeninheart.com/2021/05/path-by-xor.html" target="_blank" className="td-underline orange">Darken in Heart</a> Path single coverage
                    </p>
                    <p>
                    <a href="https://skopemag.com/2021/05/18/skopemag-news-tuesday-may-18-2021-5-pm-est" target="_blank" className="td-underline orange">Skope Magazine</a> Path single coverage
                    </p>
                    <p>
                    <a href="https://post-punk.com/dark-synth-project-xor-debuts-video-for-path/" target="_blank" className="td-underline orange">Post-punk.com</a> premiere of Path
                    </p>
                    <p>
                    <a href="https://www.destroyexist.com/2021/05/xor-path.html" target="_blank" className="td-underline orange">Destroy//Exist</a> Path single coverage
                    </p>
                    <p>
                    <a href="https://cvltnation.com/feel-the-twisted-electro-punk-pain-of-xor-path/" target="_blank" className="td-underline orange">CVLT Nation</a> premiere of Path
                    </p>
                    <p>
                    <a href="https://cvltnation.com/listen-to-xors-spot-cover-of-new-orders-temptation/" target="_blank" className="td-underline orange">CVLT Nation</a> premiere of Temptation
                    </p>
                    <p>
                    <a href="https://whitelight-whiteheat.com/playlist/wl-wh-weekly-post-punk-darkwave-dark-synth-tips-08-21/" target="_blank" className="td-underline orange">White Light // White Heat</a> God Called in Sick Today coverage
                    </p>
                    <p>
                    <a href="https://post-punk.com/xor-covers-afis-god-called-in-sick-today/" target="_blank" className="td-underline orange">Post-Punk.com</a> premiere of God Called in Sick Today
                    </p>
                    <p>
                        <a href="https://www.post-punk.com/listen-to-dark-post-punk-act-secret-shames-remix-of-calm/" target="_blank" className="td-underline orange">Post-Punk.com</a> coverage of Calm (remix)
                    </p>
                    <p>
                    <a href="http://www.destroyexist.com/2019/11/secret-shame-calm-xor-remix.html" target="_blank" className="td-underline orange">Destroy // Exist</a> coverage of Calm (remix)
                    </p>
                    <p>
                    <a href="http://www.wrir.org/2019/11/06/the-lotus-land-show-i-wanna-i-wanna/" target="_blank" className="td-underline orange">WRIR</a> coverage of Calm (remix)
                    </p>
                    <p>
                    <a href="https://www.americanpancake.com/2019/11/secret-shames-calm-remix-by-xor-has.html" target="_blank" className="td-underline orange">American Pancake</a> coverage of Calm (remix)
                    </p>
                    <p>
                    <a href="https://cultureaddicts.com/post-punk-breakouts-secret-shame-announce-new-series-of-remixes/" target="_blank" className="td-underline orange">Culture Addicts</a> coverage of Calm (remix)
                    </p>
                    <p>
                    <a href="https://tometotheweathermachine.com/secret-shame-calm-remix-by-xor/" target="_blank" className="td-underline orange">Tome To The Weather Machine</a> coverage of Calm (remix)
                    </p>
                </div>
            </div>

        </div>
    )
} 