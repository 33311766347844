export default function Bio() {
    return (
        <div className="container">
            <div className="row mb-30">
                <div className="col-lg-4 col-md-6 col-sm-12 mb-30">
                    <a href="./img/1.jpeg" target="_blank">
                        <img src="./img/1.jpeg" className="photo"/>
                    </a>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-30">
                    <p>
                    With haunting melodies, pulsating rhythms, and seductive hooks, XOR propels electronic music 
                    into a realm of sonic uncertainty. At times ambient, at times darkwave or synthpop, the 
                    tracks draw listeners into a labyrinthine soundscape where the familiar dissolves into the enigmatic. 
                    </p>
                </div>
            </div>
        </div>
    )
} 